<template>
    <div class="mx-3">
        <h2 class="px-5 py-5">{{ $t('Salary Report') }}</h2>
      <v-row class="px-10 py-1">
        <v-col md="3">
          <v-autocomplete
          :label="$t('hr.time.emp-name')"
              :items="EmpItems"
              :loading="EmpLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.employee"
              @keypress="fetchAutoCompleteEmpName($event)" 
            >
            </v-autocomplete>
        </v-col>
        
        <v-col md="3">
          <v-autocomplete
          :label="$t('hr.employee.contract')"
              :items="contractItems"
              :loading="contractLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.contract"
              @keypress="fetchAutoCompleteContract($event)" 
            >
            </v-autocomplete>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.from-date')" type="date" 
                            v-model="filters.from">
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-text-field   :label="$t('hr.time.to-date')" type="date" 
                            v-model="filters.to">
          </v-text-field>
        </v-col>
        <v-col md="3">
          <v-autocomplete
              :label="$t('hr.employee.shift')"
              :items="ShiftItems"
              :loading="ShiftLoading"
              color="#757575"
              item-text="name"
              item-value="id"
              v-model="filters.shift_id"
              @keypress="fetchAutoCompleteShift($event)"
            >
            </v-autocomplete>
        </v-col>
        <v-col md="3" >
          <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
        </v-col>
        <v-col md="3" >
          <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
        </v-col>
        <v-col md="3" >
          <v-btn color="blue" dark block  @click="exportToExcel(filters)"
            >{{ $t('hr.time.export') }}
            </v-btn>
        </v-col>
      </v-row>
  
      <v-data-table :dark="$store.state.isDarkMode"
        :headers="headers"
        :loading="loading"
        :items="tableItems"
        class="elevation-1 px-10"
        hide-default-footer
        :items-per-page="10"
      >
        <template v-slot:item.actions="{ item }">
          
        </template>
      </v-data-table>
      <v-pagination
        v-if="!loading && tableItems.length"
        @input="getAll"
        v-model="page"
        :length="Math.ceil(total / 10)"
      ></v-pagination>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import i18n from '@/i18n';
  import debounce from "../../../../../helpers/debounce";

import { saveAs } from 'file-saver';

  
  export default {
    components: {    },
    computed:{
 
    },
    data: () => ({
      
      contractItems: [],
      contractLoading: false,
      ShiftItems: [],
      ShiftLoading: false,
      EmpItems: [],
      EmpLoading: false,
      tableItems: [],
      
      loading: false,
      page: 1,
      total: 1,
      headers: [  
        { text: i18n.t("serial number"), value: "serial" },
        { text: i18n.t('hr.time.emp-name'), value: "employee_name" },
        { text: i18n.t('hr.employee.salary'), value: "salary" },
        { text: i18n.t('Bonus'), value: "bonus_value" },
        { text: i18n.t('hr.calculation.discounts'), value: "discount_value" },
        { text: i18n.t('initiate.tabs.Debt'), value: "payment_value" },
        { text: i18n.t('hr.calculation.total'), value: "final_salary" },
       
      ],
      
      filters: {
        contract: null,
        employee: null,
        shift_id: null,
        from: null,
        to: null,
      },
    }),
    methods: {
     async exportToExcel(queryParams){
         
          try {
     
      const excel = await axios.get(`hr/report/salary/export`, {
        params: {
          ...queryParams,
          
        },
        responseType: 'blob'
      });
      saveAs(excel.data)
      

    } catch (err) {
      console.log('err', err);
    } finally {
      
    }
      
      },
    
      clearFilter() {
        this.filters.employee = undefined;
        this.filters.shift_id = undefined;
        this.filters.contract = undefined;
        this.filters.from = undefined;
        this.filters.to = undefined;
       
       
        this.page = 1;
        this.getAll();
      },
   
      async getAll(page) {
        try {
          
          if (!page) this.page = 1;
          const filterTemp = {};
          Object.keys(this.filters).forEach((key) => {
            if (this.filters[key]) filterTemp[key] = this.filters[key];
          });
          this.loading = true;
          
          const res = await axios.get("hr/report/salary", {
            params: {
              size: 10,
              page: this.page,
              ...filterTemp,
            },
          });
          const arr= Array.from(res.data.data)
          if(Array.isArray(arr))
          {
            console.log(arr);
            this.tableItems = arr.map((el) => {
              
              return {
                ...el,
                
              };
            });
          }
            console.log(res.data.data);
          
          this.total = res.data.total;
        } finally {
          this.loading = false;
        }
      },
    
      async fetchAutoCompleteShift(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.ShiftLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/hr/shift/auto-complete",
            {
              params: {
                name: searchValue,
              },
            }
          );
          this.ShiftItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.ShiftLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteContract(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.contractLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/hr/contract/auto-complete",
            {
              params: {
                name: searchValue,
              },
            }
          );
          this.contractItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.contractLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteEmpName(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.EmpLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/hr/employee/auto-complete",
            {
              params: {
                name: searchValue,
              },
            }
          );
          this.EmpItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.EmpLoading = false;
        }
      }, 500)();
    },
    
    },
    created() {
      
    },
  };
  </script>
  
  <style></style>
  